define('garage/vehicle-import/service', ['exports', 'papaparse', 'garage/config/environment', 'lodash'], function (exports, _papaparse, _environment, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var cols = {
        id: 'id',
        name: 'name',
        attributes: 'attributes',
        phoneNumber: 'phoneNumber',
        licenceNumber: 'licenceNumber',
        emissionFactor: 'emissionFactor',
        priceFactor: 'priceFactor',
        dispatchArea: 'dispatchArea',
        dispatchPriority: 'dispatchPriority',
        useShift: 'useShift',
        garageLat: 'garageLat',
        garageLng: 'garageLng',
        garageAlias: 'garageAlias',
        externalId: 'externalId',
        contract: 'contract',
        paymentTerminalId: 'paymentTerminalId'
    };

    var capCols = {
        id: 'id'
    };

    exports.default = Ember.Service.extend({
        init: function init() {
            this._super.apply(this, arguments);
        },

        modalMessage: Ember.inject.service(),

        mappings: _environment.default.APP.import.capacityMappings,
        encoding: _environment.default.APP.import.encoding,

        parseFile: function parseFile(file, encoding) {
            return new Promise(function (res, rej) {
                _papaparse.default.parse(file, {
                    encoding: encoding,
                    header: true,
                    complete: res,
                    error: rej,
                    // @todo: somehow one example, tab delimited file, didn't succeed in parsing
                    // without this with automatic detection, so setting delimiter
                    delimiter: '\t'
                });
            });
        },


        // create capacity string to be used as a "unique index" for each capacity
        // configuration. this is used for filtering out possible duplicates since
        // we are not bringing over all mobirouter capacities.
        capstring: function capstring(caps) {
            return this.mappings.meaningfulCapacities.map(function (capname) {
                return caps[capname];
            }).join('-');
        },
        emptycapstring: function emptycapstring() {
            return new Array(this.mappings.meaningfulCapacities.length).fill(0).join('-');
        },
        squashCapacities: function squashCapacities(capgroups) {
            var _this = this;

            var emptycap = this.emptycapstring();
            Object.values(capgroups).forEach(function (caps) {
                // filter array of caps to unique set of meaningful caps
                var cleancaps = _lodash.default.uniqBy(caps, _this.capstring.bind(_this));
                cleancaps = cleancaps.filter(function (c) {
                    return _this.capstring(c) !== emptycap;
                });
                var vehNumber = caps[0][capCols.id];
                if (cleancaps.length !== caps.length) {
                    console.log('WARNING! ' + vehNumber + ' has "zero capacity row"');
                }
                if (vehNumber) {
                    capgroups[vehNumber] = cleancaps;
                } else {
                    // delete empty lines
                    delete capgroups[vehNumber];
                }
            });
        },


        // group capacities by each vehicle
        // @returns capacity rows grouped by vehicle
        groupCapacities: function groupCapacities(capacities) {
            var capgroups = _lodash.default.groupBy(capacities, capCols.id); // grouped by veh id
            this.squashCapacities(capgroups);
            return capgroups;
        },
        capacityConfigToVinka: function capacityConfigToVinka(cap) {
            var _this2 = this;

            return this.mappings.meaningfulCapacities.map(function (mbrcapname) {
                if (parseInt(cap[mbrcapname]) === 0) {
                    return null;
                }

                var ret = {
                    unitId: parseInt(_this2.mappings.capacityMap[mbrcapname]),
                    count: parseInt(cap[mbrcapname])
                };

                (true && !(ret.unitId > 0 && ret.unitId < 10) && Ember.assert('got capa unitId ' + ret.unitId + ' for vehicle ' + cap[capCols.id], ret.unitId > 0 && ret.unitId < 10));
                (true && !(ret.count > 0 && ret.count < 20) && Ember.assert('got capa count ' + ret.count + ' for vehicle ' + cap[capCols.id], ret.count > 0 && ret.count < 20));


                return ret;
            }).filter(function (cap) {
                return cap !== null;
            });
        },
        attributeToVinka: function attributeToVinka(attribute) {
            var id = parseInt(attribute, 10);
            return isNaN(id) ? null : { id: id };
        },
        vehiclesToVinka: function vehiclesToVinka(vehicles, capacities, prefixes, externalIds) {
            var _this3 = this;

            var capacitiesWithVehcile = {};
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = Object.keys(vehicles)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var vNbr = _step.value;

                    if (capacities[vNbr] && capacities[vNbr].length) {
                        capacitiesWithVehcile[vNbr] = capacities[vNbr];
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var vehicleDatas = Object.values(capacitiesWithVehcile).map(function (caps) {
                var number = caps[0][capCols.id];
                var vehicle = vehicles[number];
                if (!vehicle) {
                    console.log('capacities contain vehicle data which is not found', number);
                    return null;
                }
                (true && !(vehicle) && Ember.assert('vehicle ' + number + ' not found from vehicle file', vehicle));

                var name = (vehicle[cols.name] || '').trim();
                var capacityConfigs = caps.map(function (cap) {
                    return _this3.capacityConfigToVinka(cap);
                });
                var attributes = (vehicle[cols.attributes] || '').
                // .trim()
                replace(/\s+/g, '') // strip all spaces
                .split(',').map(function (attr) {
                    return _this3.attributeToVinka(attr);
                }).filter(function (a) {
                    return a !== null;
                });

                var priceFactor = parseFloat((vehicle[cols.priceFactor] || '').trim().replace(',', '.'));
                priceFactor = isNaN(priceFactor) ? 1 : priceFactor;

                var emissionFactor = _this3.parseEmissionFactor(vehicle[cols.emissionFactor]);

                var licenceNumber = (vehicle[cols.licenceNumber] || '').trim() || null;

                var phoneNumber = (vehicle[cols.phoneNumber] || '').trim();
                phoneNumber = phoneNumber.length > 0 && phoneNumber !== '0' ? phoneNumber : null; // empty phone is not accepted

                var dispatchPriority = parseInt((vehicle[cols.dispatchPriority] || '').trim(), 10);
                dispatchPriority = isNaN(dispatchPriority) ? 100 : dispatchPriority;

                var dispatchArea = (vehicle[cols.dispatchArea] || '').trim();
                dispatchArea = dispatchArea.length > 0 ? dispatchArea : null;

                var useShift = (vehicle[cols.useShift] || '').trim().length > 0 ? true : false;

                var _ref = [parseFloat(vehicle[cols.garageLat]), parseFloat(vehicle[cols.garageLng]), vehicle[cols.garageAlias] || ''],
                    lat = _ref[0],
                    lng = _ref[1],
                    alias = _ref[2];

                var garage = isNaN(lat) || isNaN(lng) ? null : { lat: lat, lng: lng, alias: alias };

                // let operator;

                var externalId = (vehicle[cols.externalId] || '').trim() || null;
                var paymentTerminalId = (vehicle[cols.paymentTerminalId] || '').trim() || null;
                var contract = _this3.parseContract(vehicle[cols.contract]);

                (true && !(number) && Ember.assert('no vehicle number found', number));
                (true && !(name) && Ember.assert('no name found for vehicle ' + number, name));
                (true && !(capacityConfigs.length > 0) && Ember.assert('got 0 capacities for vehicle ' + number, capacityConfigs.length > 0));


                if (prefixes) {
                    var numbersz = String(number);
                    var pref = prefixes.find(function (p) {
                        return numbersz.startsWith(p.prefix);
                    });
                    (true && !(pref) && Ember.assert('prefix not found for ' + numbersz, pref));

                    number = parseInt(numbersz.substring(pref.prefix.length));
                    // operator = pref.operator;
                }

                // return {number, externalId, name, operator, active: false, attributes, capacityConfigs};
                return {
                    number: number,
                    name: name,
                    licenceNumber: licenceNumber,
                    priceFactor: priceFactor,
                    emissionFactor: emissionFactor,
                    phoneNumber: phoneNumber,
                    dispatchPriority: dispatchPriority,
                    dispatchArea: dispatchArea,
                    useShift: useShift,
                    active: false,
                    attributes: attributes,
                    capacityConfigs: capacityConfigs,
                    garage: garage,
                    externalId: externalId,
                    paymentTerminalId: paymentTerminalId,
                    contract: contract
                };
            });

            var finals = vehicleDatas.filter(function (i) {
                return i !== null;
            });

            return finals;
        },
        parse: function parse(vehfile, capfile) {
            var _this4 = this;

            var capacities = void 0;
            var vehicles = void 0;

            return this.parseFile(capfile, this.encoding).then(function (parsed) {
                return _this4.groupCapacities(parsed.data);
            }).then(function (capas) {
                capacities = capas;
                return _this4.parseFile(vehfile, _this4.encoding);
            }).then(function (parsed) {
                // vehicles mapped by id as key
                vehicles = _lodash.default.keyBy(parsed.data, cols.id);
            }).then(function () {
                return _this4.vehiclesToVinka(vehicles, capacities, undefined, false);
            }).catch(function (e) {
                console.log(e);
                _this4.get('modalMessage').showDialog('Import Interrupted', e.message);
            });
        },


        /**
            Exception in conversion 0 -> 1, otherwise return the number or null.
         * @param emissionFactor
         * @returns {null|number}
         */
        parseEmissionFactor: function parseEmissionFactor(emissionFactor) {
            emissionFactor = parseFloat((emissionFactor || '').trim().replace(',', '.'));

            // if the emission factor column is missing OR the value is missing OR is invalid - return null
            return isNaN(emissionFactor) ? null : emissionFactor;
        },
        parseContract: function parseContract(contractText) {
            var contracts = (contractText || '').replace(/\s+/g, '') // strip all spaces
            .split(',').filter(function (a) {
                return !!a;
            });
            return contracts.length ? contracts : null;
        }
    });
});